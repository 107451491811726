[data-theme-version="dark"] {
    .sidebar-right {
       /*  background-color: darken($primary, 15%);
		border-radius: 12px 0 0 12px;
			
        .nav-tabs {
            background-color: lighten($d-bg, 3.2%);
            border-bottom: 1px solid $d-bg;

            .nav-link {
                &.active {
                    background-color: $d-bg;
                }
            }
        } */
		border-radius: 12px 0 0 12px;
		.sidebar-right-trigger{
			background-color:$white;
			color: $primary;
		}
		.admin-settings{
			h4{
				color: $black!important;
			}
		}
    }
}