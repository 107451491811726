.auth-footer {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media(min-width: 1920px){
    bottom: 5%;
  }
  .copyright {
    padding: 0.9375rem;
    p {
      text-align: center;
      margin: 0;
    }

    a {
      color: $primary;
    }
  }
}


