.mid-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.author-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .author-profile {
    text-align: center;
    .card-body {
      padding: 0;
    }
    .author-media {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      display: inline-block;
      img {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
        height: 250px;
        border-radius: 25px;
        object-fit: cover;
        border: 2px solid $border;
        overflow-clip-margin: unset;
      }
    }
    .author-info {
      .title {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
      span {
        display: block;
        color: $body-color;
      }
    }
    .info-list {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        border-top: 1px solid $border-color;
        padding: 18px 30px;
        color: $dark;
        a {
          color: $dark;
        }
        span {
          color: $body-color;
          font-weight: 500;
        }
      }
    }
    .card-footer {
      padding: 30px;
      display: block;
      .form-control {
        height: 45px;
        border-color: $border-color;
        overflow: hidden;
        line-height: 34px;
      }
    }
    .upload-link {
      position: absolute;
      width: 35px;
      height: 35px;
      line-height: 32px;
      background: var(--primary);
      bottom: 0;
      right: 0px;
      box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
      border-radius: 100%;
      color: #fff;
      overflow: hidden;
      border: 2px solid #fff;
      .update-flie {
        position: absolute;
        opacity: 0;
        z-index: 0;
        width: 100%;
        cursor: pointer;
        left: 0;
        height: 100%;
      }
    }
  }
}

.notification_dropdown {
  .nav-link {
    position: relative;
    color: $primary;
    background: #f4f4f4;
    border-radius: 0.5rem;
    padding: 10px;
    line-height: 1;
  }
}
.header-right {
  .notification_dropdown {
    .nav-link {
      i {
        font-size: 18px;
      }
    }
  }
}
.dz-theme-mode {
  #icon-light {
    display: none;
  }
}
@media only screen and (max-width: 575px) {
  .header-right {
    .notification_dropdown {
      .nav-link {
        padding: 0.55rem;
      }
    }
  }
}

.MuiInputBase-input {
  color: $dark !important;
}

[data-theme-version="dark"] {
  .MuiInputBase-input {
    color: $white !important;
  }
}

.input-icon-right {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.table-user-image {
  height: 30px;
  width: 30px;
  object-fit: cover;
  overflow-clip-margin: unset;
}

.cutom-date-picker {
  div {
    width: 100%;
    input {
      background: #fff;
      color: #6e6e6e;
      height: 42.5px;
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      background-clip: padding-box;
      border: 1px solid #d7dae3;
      border-radius: 0.75rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}

.custom-multi-select {
  & div[class*="control"] {
    background: #fff;
    border: 1px solid $border;
    color: $dark;
    height: 56px;
    display: flex;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    & div[class*="ValueContainer"] {
      width: 80%;
    }
  }
}

.upload-file-modal {
  .file-users-select {
    padding: 0;
  }
  .custom-file-upload-group {
    & div[class="custom-file"] {
      background: #fff;
      z-index: 0;
      border: 1px solid $border;
      color: $dark;
      height: 56px;
      display: flex;
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      border-radius: 0.75rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      & input {
        cursor: pointer;
      }
      & label[class="custom-file-label"] {
        display: flex;
        justify-content: start;
        align-items: center;
        border: none;
        height: 100%;
        &::after {
          content: "Parcourir";
          background-color: $primary;
          z-index: -1;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .file-list {
    padding-inline: 15px;
    margin: 0;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 10px;
      &-name {
        width: 80%;
      }
      &-actions {
        display: flex;
        width: 20%;
        justify-content: center;
        gap: 10px;
        & i {
          cursor: pointer;
          &.fa-trash {
            color: $danger;
          }
        }
      }
    }
  }
}

.view-document-modal {
  .modal-content {
    min-width: 700px;
    min-height: 80vh;
    .modal-body {
      .image-downloader {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 15px;
        color: $primary !important;
        a {
          text-decoration: none !important;
          cursor: pointer;
          color: $primary !important;
        }
      }
      .pg-viewer-wrapper {
        overflow: auto !important;
        .photo-viewer-container {
          width: 100% !important;
          height: 100% !important;
          img {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }
}

#proxy-renderer {
  justify-content: center;
  img {
    width: 100%;
  }
}

.event-card {
  .col-12 {
    margin-bottom: 15px;
  }
  &-title {
    text-align: start;
    text-transform: capitalize;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
  }
  &-date {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    &-start,
    &-end {
      font-weight: bold;
      margin: 0;
    }
  }
  &-link {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    &-button {
      width: 100%;
      border-radius: 0.75rem;
      font-weight: 500;
      font-size: 1rem;
      background-color: #3cc0f0;
      border: none;
      color: $white;
      &-cal {
        width: 100%;
        padding: 0.8rem 1.5rem;
        border-radius: 0.75rem;
        font-weight: 500;
        font-size: 1rem;
        background-color: #3cc0f0;
        border: none;
        color: $white;
      }
    }
  }
  &-description {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    &-text {
      margin: 0;
    }
  }
  &-participants {
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 15px;
    .fa-solid {
      padding-top: 5px;
    }
  }
}

.wroking-hours-inputs {
  display: flex;
  gap: 15px;
  justify-content: center;
  input {
    width: 50%;
  }
}

.pack-card {
  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-description {
    min-height: 458px;
  }
}

.session-details-link {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  &-button-cal {
    min-height: 56.5px;
  }
}

.profile-card {
  // max-width: 80%;
  .profile-tab {
    min-height: 70vh;
    justify-content: space-between;
    .profile-menu {
      border-right: 1px solid $border;
      &-nav {
        width: 100%;
        border-bottom: none;
        &-item {
          margin: 0;
          width: 100%;
          margin: 15px 0 !important;
          &-link {
            border: none !important;
            margin: 0 !important;
            padding: 0 !important;
            display: flex;
            align-items: center;
            i {
              font-size: 12px;
              padding-right: 1rem;
              color: $d-ctl;
            }
            &.active {
              i {
                color: $primary;
              }
            }
          }
        }
      }
    }
    &-validation-wrapper {
      position: absolute;
      bottom: 0;
      right: 13%;
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }
}

.authincation {
  &::before {
    content: "";
    background-image: url("../../images/bg-auth.webp");
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
  }
}

.auth-card {
  flex-direction: column;
  &-logo {
    text-align: center;
    img {
      width: 20%;
    }
  }
}

.payement-msg-card {
  &-logo {
    img {
      width: 20%;
    }
  }
  &-success-text {
    color: $primary;
    font-weight: bold;
  }
  &-err-text {
    color: $danger;
    font-weight: bold;
  }
}

.chat {
  height: 80vh;
  background-color: $white;
  margin-inline: 10px;
  &-list {
    padding: 10px;
    border-right: 1px solid $border;
    &-settings {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $border;
      i{
        cursor: pointer;
      }
    }
    &-button {
      text-align: center;
      margin-bottom: 1.875rem;
    }
    &-conversation {
      width: 100%;
      border-bottom: 1px solid $border-color;
      padding: 10px;
      cursor: pointer;
      &.--first {
        border-top: 1px solid $border-color;
      }
      &.--active {
        background-color: $primary;
        color: $white;
        h4,
        p {
          color: $white;
        }
      }

      &-img {
        width: 10%;
        @media only screen and (max-width: 1420px) {
          width: 20%;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 0.75rem;
          border: 2px solid #d7dae3;
          object-fit: cover;
          overflow-clip-margin: unset;
          margin-right: 10px;
        }
      }
      &-msg {
        p {
          margin: 0;
        }
        &-text {
          max-width: 280px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          color: $border;
        }
      }
    }
  }
  &-container {
    padding: 15px;
    &-messages {
      height: 60vh;
      overflow-y: auto;
      .chat-line {
        align-items: center;
        &-img {
          img {
            width: 70px;
            height: 70px;
            border-radius: 0.75rem;
            border: 2px solid #d7dae3;
            object-fit: cover;
            overflow-clip-margin: unset;
          }
        }
        &-msg {
          margin: 5px 15px;
          width: 80%;
          &.--start {
            text-align: start;
          }
          &.--end {
            text-align: end;
          }
          p {
            margin: 0;
          }
          &-time {
            font-size: 13px;
            color: $border;
            font-weight: bold;
          }
          &-file {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        &.--me {
          flex-direction: row-reverse;
        }
        &.--other {
          flex-direction: row;
        }
      }
    }

    &-input {
      margin-top: 15px;
      &-actions {
        display: flex;
        gap: 10px;
      }
    }

    &-file {
      margin-top: 10px;
      &-send {
        position: relative;
        .fa-circle-xmark {
          position: absolute;
          right: 0;
          top: -5px;
          color: $white;
        }
      }
    }
  }
}

.cutsom_select__control {
  background: #fff;
  border: 1px solid #d7dae3 !important;
  color: #6e6e6e;
  height: 56px;

  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.75rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-primary:hover,
.close:hover {
  color: $white !important;
}

.clickable-icon {
  border-radius: 10px;
  height: 20px;
  width: 30px;
  cursor: pointer;
  padding: 10px;
}

.table-card-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  .btn {
    min-width: 2rem;
    border-radius: 10px !important;
  }
}

.timeline-panel {
  border-radius: 15px;
}

.calender-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-doctor-select {
    width: 20rem;
  }
  .color-table {
    display: flex;
    align-items: center;
    justify-content: center;
    &__row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline: 10px;
    }
    &__cell--color {
      width: 3.375rem;
    }
    &__cell {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25;
      &.doctor {
        color: $danger;
      }
      &.me {
        color: $secondary;
      }
    }
    &__color {
      cursor: pointer;
      width: 3.125rem;
      height: 1.75rem;
      border-radius: 0.25rem;
      &.doctor {
        background-color: $danger;
      }
      &.me {
        background-color: $secondary;
      }
    }
  }
}

.cal-event {
  margin: 0;
  cursor: pointer;
}

.form-group {
  .disabled {
    label,
    li {
      color: $dark;
    }
  }
}

.session-link {
  text-decoration: underline;
  cursor: pointer;
}

.creation-validation {
  height: 100vh;
  .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.sidebar-elt {
  color: #717584;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-height: 67px;

  a {
    padding: 15px !important;
    span {
      padding-left: 25px;
    }
  }

  &.mm-active {
    a {
      border-radius: 12px;
      background: linear-gradient(93deg, #0c5286 0.26%, #3cc0f0 98.91%);
      box-shadow: 0px 12px 15px 0px rgba(69, 11, 90, 0.13);
      color: $white !important;
      svg {
        color: $white !important;
        &.active-arrow {
          margin-left: 35px;
        }
        path {
          fill: $white !important;
        }
      }
    }
  }
}

[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a
  > svg.active-arrow {
  display: none;
}

.reserve-sessions-wrapper {
  display: flex;
  justify-content: center;
  border-radius: 12px;
  background: linear-gradient(96deg, #0c5286 -35.71%, #3cc0f0 120.7%);
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
  min-height: 240px;
  margin-bottom: 34px;
  cursor: pointer;
  &-text {
    padding: 70px 40px 40px 40px;
    h1 {
      color: $white;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 129%; /* 46.44px */
      text-transform: uppercase;
    }
    p {
      color: $white;
    }
  }
  &-image {
    img {
      min-width: 300px;
      position: absolute;
      top: -15px;
      right: -5px;
    }
  }
}

.dash {
  margin: 15px;
  &-wdigets {
    gap: 30px;
    h1,
    h4 {
      color: $white;
    }
    &-wrapper {
      .dash-wdigets-card,
      .dash-wdigets-button:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &-card {
      border-radius: 12px;
      background: linear-gradient(98deg, #3cc0f0 0.63%, #2c9acb 97.42%);
      box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
      color: $white;
      padding: 40px;
      position: relative;
      img {
        position: absolute;
        right: -15px;
        top: -15px;
      }
      h1 {
        margin-bottom: 18px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 129%; /* 30.96px */
        text-transform: uppercase;
      }
      table {
        padding: 30px;
        thead {
          th {
            color: $white;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 129%; /* 19.35px */
            padding-top: 0;
          }
        }

        tbody {
          tr {
            td {
              color: $white;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 129%; /* 19.35px */
              padding-top: 2px;
              padding-bottom: 2px;
              border: none;
            }
          }
        }
      }
      &-date,
      &-time {
        span {
          padding-left: 8px;
        }
      }
    }
    &-button {
      border-radius: 12px;
      background: linear-gradient(98deg, #3cc0f0 0.63%, #2c9acb 97.42%);
      box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
      color: $white;
      padding: 30px;
      position: relative;
      margin-inline: 0 !important;
      cursor: pointer;
      img {
        width: 75px;
      }
      h1 {
        margin-bottom: 18px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 129%; /* 30.96px */
        text-transform: uppercase;
      }
      p {
        font-style: italic;
      }
    }
  }
}

.buy-modal {
  .modal-dialog {
    max-width: 750px;
    height: 600px;
  }
  &-header {
    border: 0;
    .close {
      padding: 10px;
      svg {
        color: #3e4954;
      }
    }
  }
  &-body {
    &-title {
      text-align: center;
      color: #0c5286;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 129%; /* 46.44px */
    }
    p {
      color: #0c5286;
      text-align: center;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 129%;
    }
  }
  .choice-card {
    border-radius: 12px;
    border: 2px solid #efefef;
    background: #fff;
    box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
    &.active {
      border-radius: 12px;
      border: 3px solid #3cc0f0;
      box-shadow: 0px 12px 23px 0px rgba(60, 192, 240, 0.21);
    }
    &-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      &-text {
        text-align: center;
        color: #404040;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 129%; /* 25.8px */
      }
      &-image {
      }
    }
  }
  &-action {
    &-return {
      border-radius: 12px;
      background: #fff;
      border: 1px solid #2385b7;
      width: 185px;
      box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
      color: #2385b7;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-right: 16px;
      span {
        padding-left: 14px;
      }
    }
    &-next {
      border-radius: 12px;
      background: linear-gradient(93deg, #0c5286 0.26%, #3cc0f0 98.91%);
      box-shadow: 0px 12px 15px 0px rgba(69, 11, 90, 0.13);
      width: 185px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
      &:hover {
        color: $white;
      }
      span {
        padding-right: 14px;
      }
      &-danger {
        background: linear-gradient(93deg, #860c0c 0.26%, #f03c3c 98.91%);
        color: #fff;
        border: none !important;
        &:hover {
          color: $white;
        }
      }
    }
    &-later {
      border-radius: 12px;
      background: linear-gradient(93deg, #0c5286 0.26%, #3cc0f0 98.91%);
      box-shadow: 0px 12px 15px 0px rgba(69, 11, 90, 0.13);
      width: 400px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
      &:hover {
        color: $white;
      }
      span {
        padding-right: 14px;
      }
    }
  }
}

.date-choice {
  min-height: 810px;
  &-title {
    text-align: center;
    color: #0c5286;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 129%; /* 46.44px */
  }
  &-week-changer {
    font-size: 20px;
    .week-changer-date {
      font-weight: bold;
      color: #000;
    }
    .fa-solid {
      margin-inline: 15px;
      cursor: pointer;
    }
  }
  &-availabilities {
    display: flex;
    margin-top: 35px;
    .slot-elt {
      width: calc(100% / 5);
      margin-inline: 5px;
      &-day {
        color: #0c5286;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 129%; /* 20.64px */
        text-transform: uppercase;
        margin-bottom: 10px;
        text-align: center;
      }
      &-box {
        border-radius: 6px;
        border: 2px solid #efefef;
        background: #fff;
        box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 129%; /* 20.64px */
        margin-bottom: 15px;
        padding: 10px;
        cursor: pointer;
        &.active {
          border-radius: 6px;
          border: 2px solid #3cc0f0;
          box-shadow: 0px 12px 23px 0px rgba(60, 192, 240, 0.21);
        }
      }
    }
  }
}

.week-changer {
  text-align: center;
  .fa-solid {
    margin-inline: 10px;
    cursor: pointer;
  }
}

.group-sessions-list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  &-elt {
    width: 124px;
    height: 113px;
    background: #f9f9f9;
    padding: 10px;
    &-title {
      color: #0c5286;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 129%; /* 19.35px */
      margin-bottom: 13px;
    }
    &-time,
    &-day {
      color: #505050;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 129%;
      margin-bottom: 10px;
      svg {
        margin-right: 5px;
        color: #0c5286;
        fill: #0c5286;
      }
    }
  }
}

.see-more {
  text-align: end;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.invoice-card {
  margin-bottom: 30px;
  margin-inline: 0;
  background: $white;
  padding: 40px;
  border-radius: 0.75rem;
  align-items: center;
  &-info {
    &-tilte {
      color: #0c5286;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 129%; /* 36.12px */
    }
    &-type {
      svg {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        margin-right: 13px;
      }
      span {
        color: #404040;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 129%; /* 23.22px */
      }
    }
  }
  &-actions {
    &-view-btn {
      width: 163px;
      height: 51px;
      border-radius: 6px;
      background: linear-gradient(93deg, #0c5286 0.26%, #3cc0f0 98.91%);
      box-shadow: 0px 12px 15px 0px rgba(69, 11, 90, 0.13);
      border: none;

      span {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
}
.exit-button {
  position: absolute;
  z-index: 9999 !important;
  right: 15px;
  top: 65px;
  padding: 15px 20px;
  border-radius: 6px;
  background: linear-gradient(93deg, #0c5286 0.26%, #3cc0f0 98.91%);
  box-shadow: 0px 12px 15px 0px rgba(69, 11, 90, 0.13);
  border: none;
  text-align: center;
  a {
    span {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    svg {
      margin-right: 10px;
    }
  }
}

.filter {
  gap: 10px;
}

.documents-seach-area {
  max-width: 55% !important;
}

@media only screen and (max-width: 767px) {
  .logo-abbr {
    max-width: 80px !important;
  }
  .dash-wdigets-card {
    padding: 15px;
    // height: 100%;
  }
  .dash-wdigets h1,
  .dash-wdigets h4 {
    text-align: center;
  }
  .dash-wdigets {
    margin: 0 !important;
  }
  .reserve-sessions-wrapper-image img,
  .dash-wdigets-card img,
  .dash-wdigets-button img {
    display: none;
  }
  .dash-wdigets .custom-col-6 {
    max-width: 100% !important;
    flex: 0 0 100%;
  }
  .dash-wdigets-card table thead th,
  .dash-wdigets-card table tbody tr td {
    font-size: 10px;
    padding: 0;
  }
  .dash-wdigets-button {
    text-align: center;
  }
  .sidebar-elt {
    font-size: 12px;
    a {
      padding: 10px !important;
      span {
        padding-left: 5px !important;
      }
    }
  }
  .sidebar-elt.mm-active a svg.active-arrow {
    display: none;
  }
  .buy-modal-body-cards {
    flex-direction: column;
  }
  .choice-card-body-image {
    width: 50%;
  }
  .slot-elt {
    width: 100%;
  }
  .invoice-card-actions {
    text-align: center;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar-elt.mm-active a svg.active-arrow {
    display: none;
  }
  .sidebar-elt a {
    padding: 10px !important;
  }
  [data-sidebar-style="mini"] .deznav .metismenu > li > a {
    width: 45px;
    height: 45px;
  }
  .deznav .metismenu > li > a svg {
    width: 30px;
    height: 30px;
    margin: 0;
  }
  .reserve-sessions-wrapper-image img {
    min-width: 20%;
    position: absolute;
    top: -90px;
    right: -5px;
    width: 20%;
  }
  .dash-wdigets-card img {
    width: 25%;
  }
  .dash-wdigets-button img {
    // width: 25%;
    // top: 45px;
    display: none;
  }
  .dash-wdigets-button-image-docs {
    top: 85px !important;
  }
  .dash-wdigets-card {
    padding: 30px;
  }
  .dash-wdigets-card table thead th {
    padding: 0;
    font-size: 13px;
  }
  .dash-wdigets-card table tbody tr td {
    padding: 5px 2px;
    font-size: 13px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1351px) {
  svg.active-arrow {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 989px) {
  .dash-wdigets .custom-col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dash-wdigets-card img {
    width: 15%;
  }
  .author-wrapper .author-profile .author-media img {
    width: 145px;
    min-width: 145px;
    max-width: 145px;
    height: 145px;
  }
  .profile-card .profile-tab .profile-menu-nav-item-link i {
    display: none;
  }
  .profile-card .profile-tab .profile-menu-nav-item-link {
    font-size: 13px;
  }
  .profile-card .profile-tab .profile-menu {
    padding-left: 0 !important;
  }
  .fc-today-button {
    display: none !important;
  }
  .fc-toolbar-title {
    font-size: 1rem !important;
  }
  .invoice-card-actions {
    &-view-btn {
      width: 90px;
      span {
        display: none;
      }
      svg {
        margin: 0;
      }
    }
  }
}
@media only screen and (min-width: 990px) and (max-width: 1200px) {
  .dash-wdigets .custom-col-6 {
    flex: 0 0 50%;
    max-width: 48%;
  }
  .dash-wdigets {
    gap: 15px;
  }
  .profile-card .profile-tab .profile-menu-nav-item-link i {
    display: none;
  }
  .profile-card .profile-tab .profile-menu-nav-item-link {
    font-size: 13px;
  }
  .profile-card .profile-tab .profile-menu {
    padding-left: 0 !important;
  }
  .invoice-card-actions {
    &-view-btn {
      width: 90px;
      span {
        display: none;
      }
      svg {
        margin: 0;
      }
    }
  }
}
@media only screen and (min-width: 1351px) and (max-width: 1385px) {
  .dash-wdigets {
    gap: 30px;
  }
}
@media only screen and (max-width: 1401px) {
  .filter {
    display: flex;
    .dropdown {
      width: 170px;
    }

    .search-area {
      max-width: none !important;
      input {
        width: 100%;
      }
    }
  }
}
